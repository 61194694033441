import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudDownloadAlt, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";

import {getResume} from "../../redux/actions/ResumeDownloadActions.jsx";

class ResumeDownloader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: faCloudDownloadAlt,
      loading: "idle"
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.setState({loading: "pending"});
    this.props.downloadResume()
      .then((response) => {
        const hasFailed = response.type === "resume/failed";
        if (hasFailed) {
          this.setState({icon: faExclamationTriangle});
        } else {
          this.setState({icon: faCloudDownloadAlt});
        }
      })
      .finally(() => this.setState({loading: "idle"}));
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {

  // }

  render() {
    return (
      <button className="p-link" onClick={this.handleClick} title="Download Resume" disabled={this.state.loading !== "idle"}>
        <FontAwesomeIcon icon={this.state.icon}/>
      </button>
    );
  }
}

ResumeDownloader.propTypes = {
  loading: PropTypes.string,
  downloadResume: PropTypes.func.isRequired
};

const mapStateToProps = (state = {status: "idle"}, action) => {
  return {
    loading: state.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    downloadResume: () => {
      return dispatch(getResume(null));
    }
  };
};

const ConnectedResumeDownloader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResumeDownloader);

export default ConnectedResumeDownloader;
