import BackendService from "../../services/BackendService.jsx";
import {createAsyncThunk} from "@reduxjs/toolkit";

const service = new BackendService();

export const getResume = createAsyncThunk(
  "resume",
  async (args, thunkAPI) => {
    try {
      const response = await service.downloadResume();
      return thunkAPI.fulfillWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
