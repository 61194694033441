import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  status: "",
  loading: "idle"
};

export const downloaderSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {}
});

export default downloaderSlice.reducer;
