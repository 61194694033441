import React, {Component} from "react";
import classNames from "classnames";
import {
  configureStore
} from "@reduxjs/toolkit";
import {Provider} from "react-redux";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from "react-router-dom";
import {
  faCode,
  faFileSignature,
  faGamepad,
  faHome,
  faNewspaper
} from "@fortawesome/free-solid-svg-icons";
import {ScrollPanel} from "primereact/scrollpanel";
import {PrimeReactProvider} from "primereact/api";

import AppBreadcrumbWithLocation from "./nav/AppBreadcrumb.jsx";
import AppMenu from "./nav/AppMenu.jsx";
import AppTopbar from "./nav/AppTopbar.jsx";
import BlogPageWithRoutes from "./components/blog/BlogPage.jsx";
import GameOfLifePage from "./components/gameOfLife/GameOfLifePage.jsx";
import HomePage from "./components/home/HomePage.jsx";
import LoggerWithLocation from "./components/Logger.jsx";
import MailPage from "./components/mail/MailPage.jsx";
import PageNotFound from "./components/error/PageNotFound.jsx";
import ProjectPage from "./components/project/ProjectPage.jsx";
import ResumePage from "./components/resume/ResumePage.jsx";
import {downloaderSlice} from "./redux/reducers/ResumeDownloadReducer.jsx";

const ResumeDownloaderStore = configureStore({
  reducer: downloaderSlice.reducer
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layoutMode: "slim",
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      darkTheme: true,
      menuActive: false
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
    this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.menu = [
      {label: "Home", icon: faHome, to: "/home"},
      {label: "Resume", icon: faFileSignature, to: "/resume"},
      {label: "Projects", icon: faCode, to: "/project"},
      {label: "Blog", icon: faNewspaper, to: "/blog"},
      {label: "Game of Life", icon: faGamepad, to: "/gameOfLife"}
    ];
  }

  onMenuClick(event) {
    this.menuClick = true;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.setState({
      topbarMenuActive: false
    });

    if (this.state.layoutMode === "overlay" && !this.isMobile()) {
      this.setState({
        overlayMenuActive: !this.state.overlayMenuActive
      });
    } else if (this.isDesktop()) {
      this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
    } else {
      this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
    }

    event.preventDefault();
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.setState({topbarMenuActive: !this.state.topbarMenuActive});
    this.hideOverlayMenu();
    event.preventDefault();
  }

  onTopbarItemClick(event) {
    this.topbarItemClick = true;

    if (this.state.activeTopbarItem === event.item) {
      this.setState({activeTopbarItem: null});
    } else {
      this.setState({activeTopbarItem: event.item});
    }

    event.originalEvent.preventDefault();
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.hideOverlayMenu();
    } else if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
      this.setState({menuActive: false});
    }
  }

  onRootMenuItemClick(event) {
  // if enabled, will toggle what is active between menu clicks
    //     this.setState({
    //       menuActive: !this.state.menuActive
    //     });
  }

  onDocumentClick(event) {
    if (!this.topbarItemClick) {
      this.setState({
        activeTopbarItem: null,
        topbarMenuActive: false
      });
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.setState({menuActive: false});
      }

      this.hideOverlayMenu();
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  hideOverlayMenu() {
    this.setState({
      overlayMenuActive: false,
      staticMenuMobileActive: false
    });
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isHorizontal() {
    return this.state.layoutMode === "horizontal";
  }

  isSlim() {
    return this.state.layoutMode === "slim";
  }

  render() {
    const layoutClassName = classNames("layout-wrapper", {
      "layout-horizontal": this.state.layoutMode === "horizontal",
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-slim": this.state.layoutMode === "slim",
      "layout-static-inactive": this.state.staticMenuDesktopInactive,
      "layout-mobile-active": this.state.staticMenuMobileActive,
      "layout-overlay-active": this.state.overlayMenuActive
    });

    return (
      <PrimeReactProvider value={{ripple: true, appendTo: "self"}}>
        <Router>
          <div className={layoutClassName} onClick={this.onDocumentClick}>
            <div>
              <AppTopbar
                onMenuButtonClick={this.onMenuButtonClick}
                onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                onTopbarItemClick={this.onTopbarItemClick}
                topbarMenuActive={this.state.topbarMenuActive}
              />

              <div className="layout-menu-container" onClick={this.onMenuClick}>
                <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height: "100%"}}>
                  <div className="layout-menu-content">
                    <AppMenu
                      model={this.menu}
                      onMenuItemClick={this.onMenuItemClick}
                      onRootMenuItemClick={this.onRootMenuItemClick}
                      layoutMode={this.state.layoutMode}
                      active={this.state.menuActive}
                    />
                  </div>
                </ScrollPanel>
              </div>

              <div className="layout-content">
                <Provider store={ResumeDownloaderStore}>
                  <AppBreadcrumbWithLocation/>
                </Provider>

                <div className="layout-content-container">
                  <Routes>
                    <Route path="/" element={<LoggerWithLocation/>}>
                      <Route index element={<Navigate replace to="/home"/>}/>
                      <Route path="home" element={<HomePage/>}/>
                      <Route path="resume" element={<ResumePage/>}/>
                      <Route path="project" element={<ProjectPage/>}/>
                      <Route path="gameOfLife" element={<GameOfLifePage/>}/>
                      <Route path="blog">
                        <Route index element={<BlogPageWithRoutes/>}/>
                        <Route path=":title" element={<BlogPageWithRoutes/>}/>
                      </Route>
                      <Route path="mail" element={<MailPage/>}/>
                      <Route path="*" element={<PageNotFound/>}/>
                    </Route>
                  </Routes>
                </div>

                {this.state.staticMenuMobileActive && <div className="layout-mask"/>}
              </div>
            </div>
          </div>
        </Router>
      </PrimeReactProvider>
    );
  }
}

export default App;
